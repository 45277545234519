var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('table',{staticClass:"table table-hover table-sm table-striped"},[_vm._m(0),_c('tbody',_vm._l((_vm.datosTabla.data),function(dato){return _c('tr',{key:dato.id},[_c('td'),_c('td',[_c('button',{staticClass:"btn btn-sm btn-default py-0",attrs:{"type":"button","data-toggle":"modal","data-target":"#modal-form-detViaje"},on:{"click":function($event){return _vm.$parent.llenarModalDetViaje(
                    dato.viaje_id,
                    dato.viaje.tipo_operacion
                  )}}},[_vm._v(" "+_vm._s(dato.viaje_id)+" ")])]),_c('td',[_vm._v(_vm._s(dato.viaje.numero_guia))]),_c('td',[_vm._v(_vm._s(dato.vehiculo.placa))]),_c('td',[_vm._v(_vm._s(dato.conductor.numero_documento))]),_c('td',[_vm._v(_vm._s(dato.viaje.transportadora.razon_social))]),_c('td',[_vm._v(_vm._s(_vm.getTipoNovedad(dato.tipo_novedad)))]),_c('td',[_vm._v(_vm._s(_vm.getTipoSubNovedad(dato.tipo_subnovedad)))]),_c('td',[_vm._v(_vm._s(dato.fecha_novedad))]),_c('td',[_vm._v(_vm._s(dato.viaje.fecha_cargue))]),_c('td',[_vm._v(_vm._s(dato.viaje.fecha_descargue))]),_c('td',[_vm._v(_vm._s(dato.direccion))]),_c('td',[_c('span',{staticClass:"badge",class:dato.viaje.estado == 1 || dato.viaje.estado == 7
                    ? 'badge-primary'
                    : dato.viaje.estado == 6 || dato.viaje.estado == 9
                    ? 'bg-navy'
                    : dato.viaje.estado == 5 || dato.viaje.estado == 10
                    ? 'badge-info'
                    : dato.viaje.estado == 4 || dato.viaje.estado == 2
                    ? 'badge-success'
                    : dato.viaje.estado == 3
                    ? 'badge-danger'
                    : 'badge-dark'},[_vm._v(_vm._s(dato.viaje.estadoViaje)+" ")])])])}),0)])]),_c('div',{staticClass:"card-footer"},[(_vm.datosTabla.total)?_c('div',{staticClass:"float-left"},[_c('p',[_vm._v(" Mostrando del "),_c('b',[_vm._v(_vm._s(_vm.datosTabla.from))]),_vm._v(" al "),_c('b',[_vm._v(_vm._s(_vm.datosTabla.to))]),_vm._v(" de un total: "),_c('b',[_vm._v(_vm._s(_vm.datosTabla.total))]),_vm._v(" Registros ")])]):_c('div',{staticClass:"float-left"},[_vm._m(1)]),_c('pagination',{attrs:{"data":_vm.datosTabla,"limit":10,"align":"right"},on:{"pagination-change-page":_vm.getNovedades}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('th'),_c('th',[_vm._v("Viaje")]),_c('th',[_vm._v("Guia")]),_c('th',[_vm._v("Vehiculo")]),_c('th',[_vm._v("Conductor")]),_c('th',[_vm._v("Transportador")]),_c('th',[_vm._v("Novedad")]),_c('th',[_vm._v("Subnovedad")]),_c('th',[_vm._v("Fecha novedad")]),_c('th',[_vm._v("Fecha cargue")]),_c('th',[_vm._v("Fecha descargue")]),_c('th',[_vm._v("Ubicacion")]),_c('th',[_vm._v("Estado")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('span',{staticClass:"badge badge-danger"},[_vm._v("No hay registros para mostrar")])])
}]

export { render, staticRenderFns }