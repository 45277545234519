<template>
  <div>
    <div class="card">
      <div class="card-body">
        <table class="table table-hover table-sm table-striped">
          <thead>
            <th></th>
            <th>Viaje</th>
            <th>Guia</th>
            <th>Vehiculo</th>
            <th>Conductor</th>
            <th>Transportador</th>
            <th>Novedad</th>
            <th>Subnovedad</th>
            <th>Fecha novedad</th>
            <th>Fecha cargue</th>
            <th>Fecha descargue</th>
            <th>Ubicacion</th>
            <th>Estado</th>
          </thead>
          <tbody>
            <tr v-for="dato in datosTabla.data" :key="dato.id">
              <td></td>
              <td>
                <button
                  type="button"
                  class="btn btn-sm btn-default py-0"
                  data-toggle="modal"
                  data-target="#modal-form-detViaje"
                  @click="
                    $parent.llenarModalDetViaje(
                      dato.viaje_id,
                      dato.viaje.tipo_operacion
                    )
                  "
                >
                  {{ dato.viaje_id }}
                </button>
              </td>
              <td>{{ dato.viaje.numero_guia }}</td>
              <td>{{ dato.vehiculo.placa }}</td>
              <td>{{ dato.conductor.numero_documento }}</td>
              <td>{{ dato.viaje.transportadora.razon_social }}</td>
              <td>{{ getTipoNovedad(dato.tipo_novedad) }}</td>
              <td>{{ getTipoSubNovedad(dato.tipo_subnovedad) }}</td>
              <td>{{ dato.fecha_novedad }}</td>
              <td>{{ dato.viaje.fecha_cargue }}</td>
              <td>{{ dato.viaje.fecha_descargue }}</td>
              <td>{{ dato.direccion }}</td>
              <td>
                <span
                  class="badge"
                  :class="
                    dato.viaje.estado == 1 || dato.viaje.estado == 7
                      ? 'badge-primary'
                      : dato.viaje.estado == 6 || dato.viaje.estado == 9
                      ? 'bg-navy'
                      : dato.viaje.estado == 5 || dato.viaje.estado == 10
                      ? 'badge-info'
                      : dato.viaje.estado == 4 || dato.viaje.estado == 2
                      ? 'badge-success'
                      : dato.viaje.estado == 3
                      ? 'badge-danger'
                      : 'badge-dark'
                  "
                  >{{ dato.viaje.estadoViaje }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-footer">
        <div class="float-left" v-if="datosTabla.total">
          <p>
            Mostrando del <b>{{ datosTabla.from }}</b> al
            <b>{{ datosTabla.to }}</b> de un total:
            <b>{{ datosTabla.total }}</b> Registros
          </p>
        </div>
        <div class="float-left" v-else>
          <p>
            <span class="badge badge-danger"
              >No hay registros para mostrar</span
            >
          </p>
        </div>
        <pagination
          :data="datosTabla"
          @pagination-change-page="getNovedades"
          :limit="10"
          align="right"
        >
        </pagination>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
export default {
  name: "ReporteNovedadeshseTabla",
  components: {
    pagination,
  },
  data() {
    return {
      page: 1,
      datosTabla: {},
    };
  },
  methods: {
    async getNovedades(page = 1) {
      this.$parent.cargando = true;
      this.page = page;
      let me = this;
      await axios
        .get("/api/hidrocarburos/reportenovedades/Tabla?page=" + page, {
          params: me.$parent.filtros,
        })
        .then(function(response) {
          me.$parent.cargando = false;
          me.datosTabla = response.data;
        })
        .catch(function(error) {
          me.$parent.cargando = false;
          me.$swal({
            icon: "error",
            title: "Ocurrió un error: " + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getTipoNovedad(tipo_novedad) {
      let ntipoNovedad =
        tipo_novedad == 1
          ? "GPS"
          : tipo_novedad == 2
          ? "CALCULO RUTAS"
          : tipo_novedad == 3
          ? "INSPECCIONES"
          : "GEOCERCAS";

      return ntipoNovedad;
    },

    getTipoSubNovedad(tipo_subnovedad) {
      let ntipoSubnovedad =
        tipo_subnovedad == 5
          ? "Exceso de velocidad"
          : tipo_subnovedad == 7
          ? "Aceleración Brusca"
          : tipo_subnovedad == 8
          ? "Frenada Brusca"
          : tipo_subnovedad == 9
          ? "Curva Brusca"
          : tipo_subnovedad == 10
          ? "Maltrato Suspension"
          : tipo_subnovedad == 11
          ? "Alerta Impacto"
          : tipo_subnovedad == 12
          ? "Botón Emergencia"
          : "";
      return ntipoSubnovedad;
    },
  },
  mounted() {
    this.getNovedades();
  },
};
</script>
